<template>
	<div class="pentathlon">
		<SubHeader title="Pentathlon" subtitle="ペンタスロン" />

		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-12 mb-3">
					<h2 class="mb-2">ペンタスロン2020 ランキング</h2>
					<h4>トータルランキング</h4>
					<div class="table-responsive mb-5">
						<table class="table table-striped">
							<thead class="thead-dark">
								<tr>
									<th scope="col" class="text-nowrap">#</th>
									<th scope="col" class="text-nowrap">Team</th>
									<th scope="col" class="text-nowrap">Player</th>
									<th scope="col" class="text-nowrap">Corks</th>
									<th scope="col" class="text-nowrap">301</th>
									<th scope="col" class="text-nowrap">Baseball</th>
									<th scope="col" class="text-nowrap">501</th>
									<th scope="col" class="text-nowrap">No Point Cricket</th>
									<th scope="col" class="text-nowrap">Total Pts</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(player, index) in list">
									<tr :key="'rank' + index">
										<td class="text-nowrap">{{ player.no }}</td>
										<td class="text-nowrap">{{ player.team_name }}</td>
										<td class="text-nowrap">
											{{ player.player_name }}（{{ player.id }}）
										</td>
										<td class="text-nowrap score">{{ player.corks }}</td>
										<td class="text-nowrap score">{{ player.g301 }}</td>
										<td class="text-nowrap score">{{ player.baseball }}</td>
										<td class="text-nowrap score">{{ player.g501 }}</td>
										<td class="text-nowrap score">{{ player.cricket }}</td>
										<td class="text-nowrap score">{{ player.pnts }}</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>

					<h3>部門別ランキング</h3>
					<div class="row">
						<div class="col-lg-4 mb-3">
							<h4>Corks</h4>
							<table class="table table-striped mb-4">
								<thead class="thead-dark">
									<tr>
										<th scope="col" class="text-nowrap">#</th>
										<th scope="col" class="text-nowrap">Player</th>
										<th scope="col" class="text-nowrap">Pts</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(player, index) in corks">
										<tr :key="'rank' + index">
											<td class="text-nowrap">{{ player.no }}</td>
											<td class="text-nowrap">
												{{ player.player_name }}
											</td>
											<td class="text-nowrap score">{{ player.pnts }}</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
						<div class="col-lg-4 mb-3">
							<h4>301 Double-in/Double-out</h4>
							<table class="table table-striped mb-4">
								<thead class="thead-dark">
									<tr>
										<th scope="col" class="text-nowrap">#</th>
										<th scope="col" class="text-nowrap">Player</th>
										<th scope="col" class="text-nowrap">Pts</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(player, index) in g301">
										<tr :key="'rank' + index">
											<td class="text-nowrap">{{ player.no }}</td>
											<td class="text-nowrap">
												{{ player.player_name }}
											</td>
											<td class="text-nowrap score">{{ player.pnts }}</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
						<div class="col-lg-4 mb-3">
							<h4>Baseball</h4>
							<table class="table table-striped mb-4">
								<thead class="thead-dark">
									<tr>
										<th scope="col" class="text-nowrap">#</th>
										<th scope="col" class="text-nowrap">Player</th>
										<th scope="col" class="text-nowrap">Pts</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(player, index) in baseball">
										<tr :key="'rank' + index">
											<td class="text-nowrap">{{ player.no }}</td>
											<td class="text-nowrap">
												{{ player.player_name }}
											</td>
											<td class="text-nowrap score">{{ player.pnts }}</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
						<div class="col-lg-4 mb-3">
							<h4>501 Open-in/Double-out</h4>
							<table class="table table-striped mb-4">
								<thead class="thead-dark">
									<tr>
										<th scope="col" class="text-nowrap">#</th>
										<th scope="col" class="text-nowrap">Player</th>
										<th scope="col" class="text-nowrap">Pts</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(player, index) in g501">
										<tr :key="'rank' + index">
											<td class="text-nowrap">{{ player.no }}</td>
											<td class="text-nowrap">
												{{ player.player_name }}
											</td>
											<td class="text-nowrap score">{{ player.pnts }}</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
						<div class="col-lg-4 mb-3">
							<h4>No Point Cricket</h4>
							<table class="table table-striped mb-4">
								<thead class="thead-dark">
									<tr>
										<th scope="col" class="text-nowrap">#</th>
										<th scope="col" class="text-nowrap">Player</th>
										<th scope="col" class="text-nowrap">Pts</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(player, index) in cricket">
										<tr :key="'rank' + index">
											<td class="text-nowrap">{{ player.no }}</td>
											<td class="text-nowrap">
												{{ player.player_name }}
											</td>
											<td class="text-nowrap score">{{ player.pnts }}</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>

					<h2 class="mb-2">ペンタスロンとは</h2>
					<p>「Darts Pentathlon」とは以下の5種目のゲームから構成されます。</p>
					<table class="table table-striped mb-5">
						<thead>
							<tr>
								<th>ゲーム</th>
								<th>ルール説明</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row">Corks（コークス）</th>
								<td>
									1ラウンド3本のダーツを5ラウンド（15ダーツ）BULLに投げる。ゲームの目的は出来るだけ多くBULLに的中させることである。ダブルブル（インナーブル）は2本、シングルブル（アウターブル）は1本として数える。BULLに入った本数をカウントする。最高取得本数は30本である。
									<br />※投げたダーツが跳ね返った場合はそのダーツを再投出来る。
								</td>
							</tr>
							<tr>
								<th scope="row">301 Double-in/Double-out</th>
								<td>
									301点の持ち点を減らしていき丁度0点にするゲームである。1ラウンドに3本ずつ投げる。先ずダブルに入れてから減点が開始され、最後は丁度0点になるダブルで終了しなければならない。最小ダーツ数は6本である。リミットダーツ数は39本でそれ以上は0ポイントとなる。
								</td>
							</tr>
							<tr>
								<th scope="row">Baseball（ベイスボール）</th>
								<td>
									Baseballはダーツゲームに野球の大まかな遊び方を取り入れたゲームである。使用するナンバーは1～9で9ラウンドである。ラウンドの数と同じナンバーに各ラウンド3本ずつ投げる。そのナンバーのシングルに入れば1点・ダブルに入れば2点・トリプルに入れば3点で、1ラウンドの最大得点は9点で最大取得点数は81点である。
								</td>
							</tr>
							<tr>
								<th scope="row">501 Open-in/Double-out</th>
								<td>
									501点の持ち点を減らしていき丁度0点にするゲームである。1ラウンドに3本ずつ投げる。ナンバーの何処からスタートしても良い。最後は丁度0点になるダブルで終了しなければならない。最小ダーツ数は9ダーツである。リミットダーツ数は45本でそれ以上は0ポイントとなる。
								</td>
							</tr>
							<tr>
								<th scope="row">
									No Point Cricket（ノーポイント・クリケット）
								</th>
								<td>
									15～20までのナンバーとBULLを使用し各3本ずつ投げ、全部で何本のダーツ数で終了できるかを競うゲームである。シングルは1本・ダブルは2本・トリプルは3本として数える。1つのナンバーに3本以上的中しても成績には反映しない。狙うナンバーは何処からでも良い。終了可能な最小ダーツ数は8本である。リミットダーツ数は42本でそれ以上は0ポイントである。
								</td>
							</tr>
						</tbody>
					</table>

					<h2 class="mb-2">ペンタスロンのやり方</h2>
					<ol class="ml-4">
						<li>
							ベニューにて、
							<a href="./pdf/pentathlon2020.pdf" target="_blank"
								>ペンタスロン用のシート</a
							>をもらいます
						</li>
						<li>
							一人、または複数人で順番にゲームを行い、ポイント表に応じたポイントをゲームごとに記入します
						</li>
						<li>
							合計ポイントを記入後、ベニューの担当者にサインをもらい、ベニューに提出します
						</li>
					</ol>
					<h4>注意事項</h4>
					<ul class="ml-4">
						<li class="pa-2"></li>
						<li>
							※事務局宛にFAX、または写真添付にてメールを送信お願いします。
						</li>
						<li>
							※２回目以降はトータルポイント・各種ポイントが自己記録更新した用紙だけを送信お願いします。
						</li>
						<li>
							※１日に何度でもトライして頂けます（必ず５種目全てを実施してください）。
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

export default {
	name: "pentathlon",
	components: {
		SubHeader
	},
	data() {
		return {
			isLoading: true,
			list: [],
			corks: [],
			g301: [],
			baseball: [],
			g501: [],
			cricket: [],
			group_id: null
		};
	},
	mounted: function() {
		this.getData();
	},
	methods: {
		getData: function() {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666"
			});
			let params = {};
			this.axios
				.get(
					"https://script.google.com/macros/s/AKfycbzukIOOleksNt7SMsFCRsAizNi0OC4ozhsN94TaMCU3qPEzIgY/exec",
					{
						params: params
					}
				)
				.then(res => {
					//console.log("res:" + JSON.stringify(res.data));
					this.list = res.data.total;
					this.corks = res.data.corks;
					this.g301 = res.data.g301;
					this.baseball = res.data.baseball;
					this.g501 = res.data.g501;
					this.cricket = res.data.cricket;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}
h4 {
	font-size: 1rem;
	font-weight: bold;
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
a {
	text-decoration: underline;
}
td.score {
	text-align: right;
}
</style>
